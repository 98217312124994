export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/about": [3],
		"/contact": [4],
		"/privacy": [5],
		"/projects": [6],
		"/projects/ai-coaching-mentorship": [8],
		"/projects/ai-coding-assistants": [9],
		"/projects/ai-education-courses": [10],
		"/projects/ai-skills-assessment": [11],
		"/projects/caregiver-training": [12],
		"/projects/energy-efficiency-ai": [13],
		"/projects/health-info-translation": [14],
		"/projects/marketing-ai-guide": [15],
		"/projects/meld-unified-llm-library": [16],
		"/projects/[slug]": [~7],
		"/services": [17],
		"/services/advanced-ai-techniques-and-tools": [19],
		"/services/ai-application-development": [20],
		"/services/ai-powered-content-solutions": [21],
		"/services/ai-strategy-and-integration": [22],
		"/services/education-and-training-solutions": [23],
		"/services/retrieval-augmented-generation": [24],
		"/services/[slug]": [~18],
		"/terms": [25]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';